export const GET_TOKEN = 'GET_TOKEN';
export const ESTABLISH_SESSION = 'ESTABLISH_SESSION';
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG_ITEM = "GET_BLOG_ITEM";
export const GET_NEXT_BLOG_ITEM = "GET_NEXT_BLOG_ITEM";
export const GET_BLOG_LANDING = 'GET_BLOG_LANDING';
export const GET_HOMEPAGE = 'GET_HOMEPAGE';
export const CAROUSEL_CURRENT_SLIDE = 'CAROUSEL_CURRENT_SLIDE';
export const USERS_ERROR = 'USERS_ERROR';
export const SORT_BY_BLOG_SUBJECT = 'SORT_BY_BLOG_SUBJECT';
export const CAROUSEL_HOVER_STATE = 'CAROUSEL_HOVER_STATE';
export const CAROUSEL_INTERVAL = 'CAROUSEL_INTERVAL';
export const CAROUSEL_TEXT = 'CAROUSEL_TEXT';
export const CAROUSEL_BKG_COLOR = 'CAROUSEL_BKG_COLOR';
export const CAROUSEL_IMG_WIDTH = 'CAROUSEL_IMG_WIDTH';
export const CAROUSEL_TOTAL_SLIDES = 'CAROUSEL_TOTAL_SLIDES';
export const GET_ORDERED_SLIDES = 'GET_ORDERED_SLIDES';
export const NAVIGATION_STATE = 'NAVIGATION_STATE';
export const IS_INTERSECTING = 'IS_INTERSECTING';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT_ITEM = 'GET_PROJECT_ITEM';
export const SORT_BY_PROJECT_EXPERTISE = 'SORT_BY_PROJECT_EXPERTISE';
export const GET_NEXT_PROJECT_ITEM = 'GET_NEXT_PROJECT_ITEM';
export const GET_PROJECT_LANDING = 'GET_PROJECT_LANDING';
export const GET_SURF_API_ENDPOINTS = 'GET_SURF_API_ENDPOINTS';
export const GET_GEO_LOCATION = 'GET_GEO_LOCATION';
export const GEO_LOCATION_ERROR = 'GEO_LOCATION_ERROR';
export const GET_LOCATION_OBJECT = 'GET_LOCATION_OBJECT';
export const GET_SPOT_FORECAST = 'GET_SPOT_FORECAST';
export const GET_CLOSE_LOCATIONS = 'GET_CLOSE_LOCATIONS';
export const GET_CLOSE_SURFSPOTS = 'GET_CLOSE_SURFSPOTS';
export const GET_SWELL_FORECAST = 'GET_SWELL_FORECAST';
export const GET_WIND_FORECAST = 'GET_WIND_FORECAST';
export const GET_SURFLINE_WIND_FORECAST = 'GET_SURFLINE_WIND_FORECAST';
export const GET_MAX_WAVE_HEIGHT = 'GET_MAX_WAVE_HEIGHT';
export const GET_TIDE_STATIONS = 'GET_TIDE_STATIONS';
export const GET_NDBC_STATIONS = 'GET_NDBC_STATIONS';
export const GET_TIDE_FORECAST = 'GET_TIDE_FORECAST';
export const GET_WEATHER = 'GET_WEATHER';
export const GET_WEATHER_STATIONS = 'GET_WEATHER_STATIONS';
export const GET_WATER_TEMP = 'GET_WATER_TEMP';
export const GET_WEATHER_FORECAST = 'GET_WEATHER_FORECAST';
export const GET_UV_FORECAST = 'GET_UV_FORECAST';
export const GET_CURRENT_SWELL = 'GET_CURRENT_SWELL';
export const GET_SEARCH_CLOSE_SURFSPOTS = 'GET_SEARCH_CLOSE_SURFSPOTS';
export const SEARCH_OPEN_STATE = 'SEARCH_OPEN_STATE';
export const LOGIN_OPEN_STATE = 'LOGIN_OPEN_STATE';
export const CLOSE_SPOTS_OPEN_STATE = 'CLOSE_SPOTS_OPEN_STATE';
export const GET_ACTIVE_LOCATION = 'GET_ACTIVE_LOCATION';
export const GET_MULTI_VIEW_FORECAST = 'GET_MULTI_VIEW_FORECAST';
export const LOAD_VIEW = 'LOAD_VIEW';
export const GET_ABOUT = 'GET_ABOUT';
export const GET_CONTACT = 'GET_CONTACT';
export const GET_HOMEPAGE_CAROUSEL_ITEMS = 'GET_HOMEPAGE_CAROUSEL_ITEMS';
export const GET_MULTI_VIEW_SWELL_FORECAST = 'GET_MULTI_VIEW_SWELL_FORECAST';
export const GET_MAX_WAVE_HEIGHT_MULTI_VIEW = 'GET_MAX_WAVE_HEIGHT_MULTI_VIEW';
export const GET_ACTIVE_SURF_SPOT = 'GET_ACTIVE_SURF_SPOT';
export const GET_LAT = 'GET_LAT';
export const GET_LNG = 'GET_LNG';
export const MULTI_VIEW = 'MULTI_VIEW';
export const SINGLE_VIEW = 'SINGLE_VIEW';
export const GET_SURFER_CREDENTIALS = 'GET_SURFER_CREDENTIALS';
