import React, { Fragment } from 'react';

export const WaveConditionsSVGPath = () => {
    return (
        <Fragment>
			<g>
				<path d="M49.84,23.11c-5.86,0-10.62,4.77-10.62,10.62c0,5.8,4.77,10.53,10.62,10.53c5.86,0,10.62-4.77,10.62-10.62
		C60.46,27.83,55.69,23.11,49.84,23.11z"/>
				<path d="M51.54,14.24V7.06c0-0.92-0.78-1.7-1.7-1.7c-0.92,0-1.7,0.78-1.7,1.7v7.18c0,0.92,0.78,1.7,1.7,1.7
		C50.76,15.94,51.54,15.16,51.54,14.24z"/>
				<path d="M37.74,19.89c-0.01-0.42-0.17-0.79-0.46-1.06l-5.09-5.08c-0.32-0.32-0.76-0.51-1.21-0.51c-0.01,0-0.02,0-0.03,0
		c-0.42,0.01-0.79,0.17-1.06,0.46c-0.37,0.37-0.56,0.83-0.55,1.28c0.01,0.42,0.17,0.79,0.46,1.06l5.09,5.08
		c0.31,0.31,0.8,0.53,1.17,0.53c0.34,0,0.81-0.12,1.09-0.45l0.08-0.08C37.56,20.8,37.75,20.35,37.74,19.89z"/>
				<path d="M30.34,31.93h-7.18c-0.92,0-1.7,0.78-1.7,1.7c0.05,0.97,0.75,1.7,1.7,1.7h7.18c0.92,0,1.7-0.78,1.7-1.7
		S31.26,31.93,30.34,31.93z"/>
				<path d="M37.74,47.34c-0.01-0.42-0.17-0.79-0.46-1.06l-0.08-0.08c-0.27-0.29-0.64-0.45-1.06-0.46c-0.01,0-0.02,0-0.03,0
		c-0.45,0-0.88,0.18-1.21,0.51l-5.04,5.04c-0.33,0.33-0.52,0.78-0.51,1.24c0.01,0.42,0.17,0.79,0.46,1.06
		c0.35,0.35,0.84,0.57,1.21,0.57c0.34,0,0.81-0.12,1.09-0.45l0.08-0.08l5.04-5.04C37.56,48.25,37.75,47.79,37.74,47.34z"/>
				<path d="M51.54,60.31v-7.18c0-0.92-0.78-1.7-1.7-1.7c-0.92,0-1.7,0.78-1.7,1.7v7.18c0,0.92,0.78,1.7,1.7,1.7
		C50.76,62.01,51.54,61.23,51.54,60.31z"/>
				<path d="M63.57,45.73c-0.01,0-0.02,0-0.03,0c-0.42,0.01-0.79,0.17-1.06,0.46l-0.04,0.04c-0.33,0.33-0.51,0.78-0.51,1.24
		c0.01,0.42,0.17,0.79,0.46,1.06l5.09,5.08c0.31,0.31,0.8,0.53,1.17,0.53c0.34,0,0.81-0.12,1.09-0.45l0.08-0.08
		c0.33-0.33,0.51-0.78,0.51-1.24c-0.01-0.42-0.17-0.79-0.46-1.06l-5.09-5.08C64.46,45.91,64.02,45.73,63.57,45.73z"/>
				<path d="M76.51,31.93h-7.18c-0.92,0.05-1.7,0.8-1.7,1.7c0,0.92,0.78,1.7,1.7,1.7h7.18c0.92,0,1.7-0.78,1.7-1.7
		S77.43,31.93,76.51,31.93z"/>
				<path d="M70.33,14.75c-0.01-0.42-0.17-0.79-0.46-1.06c-0.37-0.36-0.8-0.55-1.25-0.55c-0.01,0-0.02,0-0.03,0
		c-0.42,0.01-0.79,0.17-1.06,0.46l-5.08,5.09c-0.33,0.33-0.52,0.78-0.51,1.24c0.01,0.42,0.17,0.79,0.46,1.06l0.12,0.13
		c0.18,0.21,0.46,0.45,1.09,0.45c0.34,0,0.81-0.12,1.09-0.45l0.08-0.08l5.04-5.04C70.15,15.66,70.34,15.21,70.33,14.75z"/>
				<path d="M94.5,70.1c0-0.92-0.78-1.7-1.7-1.7c-4.09,0-6.37,1.94-8.2,3.5l-0.36,0.31c-1.57,1.39-2.93,2.59-5.8,2.59
		c-3.06,0-4.39-1.25-6.07-2.83c-1.76-1.58-3.99-3.57-8.28-3.57c-4.2,0-6.42,1.99-8.21,3.59l-0.24,0.21
		c-1.57,1.39-2.93,2.59-5.8,2.59c-2.85,0-4.26-1.12-6.07-2.83c-1.76-1.58-3.99-3.57-8.19-3.57c-4.19,0-6.42,1.99-8.21,3.59
		l-0.24,0.21c-1.57,1.39-2.93,2.59-5.8,2.59c-2.85,0-4.26-1.12-6.07-2.83c-1.76-1.58-3.99-3.57-8.19-3.57c-0.92,0-1.7,0.78-1.7,1.7
		c0,0.97,0.73,1.7,1.7,1.7c2.85,0,4.25,1.12,6.07,2.83c1.76,1.58,3.99,3.57,8.19,3.57c4.19,0,6.42-1.99,8.21-3.59l0.24-0.21
		c1.57-1.39,2.93-2.59,5.8-2.59c2.85,0,4.25,1.12,6.07,2.83c1.76,1.58,3.99,3.57,8.19,3.57c4.2,0,6.42-1.99,8.21-3.59l0.24-0.21
		c1.57-1.39,2.93-2.59,5.8-2.59c2.85,0,4.25,1.12,6.07,2.83c1.76,1.58,3.99,3.57,8.28,3.57c4.09,0,6.37-1.94,8.2-3.5L87,74.39
		c1.57-1.39,2.93-2.59,5.8-2.59C93.72,71.8,94.5,71.02,94.5,70.1z"/>
				<path d="M41.65,90.93c1.76,1.58,3.99,3.57,8.19,3.57c4.2,0,6.42-1.99,8.21-3.59l0.24-0.22c1.57-1.39,2.93-2.59,5.8-2.59
		c2.85,0,4.25,1.12,6.07,2.83c1.76,1.58,3.99,3.57,8.28,3.57c4.09,0,6.37-1.94,8.2-3.5l0.36-0.31c1.57-1.39,2.92-2.59,5.8-2.59
		c0.92,0,1.7-0.78,1.7-1.7c0-0.88-0.85-1.6-1.9-1.6c-4.08,0-6.36,1.94-8.2,3.5l-0.35,0.31c-1.57,1.39-2.93,2.59-5.8,2.59
		c-2.67,0-3.89-1.04-5.3-2.25c-0.19-0.16-0.39-0.34-0.61-0.52c-1.83-1.64-4.06-3.63-8.35-3.63c-4.2,0-6.42,1.99-8.21,3.59
		l-0.24,0.21c-1.57,1.39-2.93,2.59-5.8,2.59c-2.85,0-4.26-1.12-6.07-2.83c-1.76-1.58-3.99-3.57-8.19-3.57
		c-4.19,0-6.42,1.99-8.21,3.59c-1.74,1.53-3.07,2.71-5.95,2.71c-2.85,0-4.25-1.12-6.07-2.83c-1.76-1.58-3.99-3.57-8.19-3.57
		c-0.92,0-1.7,0.78-1.7,1.7s0.78,1.7,1.7,1.7c2.85,0,4.25,1.12,6.07,2.83c1.76,1.58,3.99,3.57,8.19,3.57c4.2,0,6.42-1.99,8.21-3.59
		l0.25-0.22c1.57-1.39,2.92-2.59,5.8-2.59C38.43,88.09,39.83,89.22,41.65,90.93z"/>
		</g>
        </Fragment>
    )
}
