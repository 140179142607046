import React, {Fragment} from 'react';

export const UserSVGPath = () => {
    return (
        <Fragment>
            <g>
                <path
                    d="M49.57,1c-26.8,0-48.5,21.7-48.5,48.5c0,10.43,3.52,20.31,9.22,28.31c0.02,0.01-0.17-0.18,0,0
		C19.01,89.82,33.56,98,49.57,98c16.25,0,30.62-8.12,39.35-20.37c0-0.12,0,0.12,0,0c5.7-7.88,9.15-17.7,9.15-28.13
		C98.07,22.7,76.36,1,49.57,1L49.57,1z M49.57,93.39c-14.19,0-26.8-6.79-34.8-17.34c5.21-14.79,19.04-24.73,34.8-24.73h0.12h0.12
		c15.76,0,29.58,9.94,34.8,24.73C76.36,86.48,63.75,93.39,49.57,93.39L49.57,93.39z M35.38,32.52c0-7.76,6.31-14.19,14.19-14.19
		c7.76,0,14.19,6.31,14.19,14.19c0,7.76-6.31,14.07-14.07,14.19h-0.12h-0.12C41.68,46.59,35.38,40.28,35.38,32.52L35.38,32.52z
		 M87.52,71.32C82.3,59.68,72.24,51.2,60.11,48.04c4.97-3.39,8.24-9.09,8.24-15.52c0-10.31-8.37-18.79-18.79-18.79
		s-18.79,8.49-18.79,18.79c0,6.43,3.27,12.12,8.24,15.52c-12.12,3.15-22.19,11.64-27.4,23.28C7.85,64.9,5.79,57.5,5.79,49.5
		c0-24.13,19.64-43.89,43.89-43.89S93.46,25.37,93.46,49.5C93.46,57.5,91.28,64.9,87.52,71.32L87.52,71.32z"/>
            </g>
        </Fragment>
    )
}
