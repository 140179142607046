import React, { Fragment } from 'react';

export const SwellSpectraCompassSVGPath = () => {
    return (
        <Fragment>
			<path d="M994.87,10.9c-485.76,0-889.4,351.57-970.38,814.14l-3.4-0.6l-0.37,2.1l3.41,0.6c-9.49,54.9-14.45,111.34-14.45,168.95
	c0,302.68,136.51,573.47,351.3,754.19l-0.95,1.13l1.63,1.38l0.96-1.14c171.1,143.33,391.59,229.63,632.25,229.63
	c0.53,0,1.05-0.01,1.58-0.01l0,0.4l2.14,0l0-0.41c542.4-2,981.48-442.3,981.48-985.17C1980.07,451.99,1538.98,10.9,994.87,10.9z
	 M1683.96,307.01c61.06,61.06,113.15,128.97,155.69,202.81l-45.32,26.12c-53.47-92.7-122.6-175.23-203.77-243.97l31.65-37.62
	l-1.63-1.37l-31.65,37.62c-82-69.12-176.22-124.2-278.96-161.54l17.95-49.09c15.54,5.66,30.98,11.72,46.28,18.2
	C1490.24,147.24,1594.46,217.5,1683.96,307.01z M1905.8,960.64l-86.03,38.08l86.09,31.36c-4.03,111.07-27.87,218.84-71.02,320.85
	c-45.91,108.55-111.65,206.04-195.37,289.77c-83.72,83.72-181.22,149.46-289.77,195.37c-99.25,41.98-203.96,65.67-311.85,70.63
	l-40.61-91.75l-33.6,92.22c-112.04-3.74-220.76-27.6-323.62-71.1c-108.55-45.91-206.04-111.65-289.77-195.37
	c-83.72-83.72-149.46-181.22-195.37-289.77c-42.48-100.44-66.23-206.48-70.8-315.74l86.61-38.33L83.8,965.19
	c3.7-112.15,27.57-220.98,71.11-323.93c45.91-108.55,111.65-206.04,195.37-289.77C434,267.77,531.49,202.04,640.04,156.12
	c100.74-42.61,207.11-66.37,316.71-70.84l38.39,86.75l31.69-86.98c111.78,3.82,220.25,27.67,322.87,71.08
	c108.55,45.91,206.04,111.65,289.77,195.37c83.72,83.72,149.46,181.22,195.37,289.77C1877.8,742.82,1901.61,850.09,1905.8,960.64z
	 M1325.9,79.24l-17.95,49.09c-97.75-35.27-203.17-54.52-313.08-54.52c-0.12,0-0.23,0-0.35,0l-0.11-49.73l-2.14,0l0.11,49.74
	c-110.05,0.29-215.58,19.85-313.37,55.5l-17.8-49.15c106.63-38.91,218.64-58.6,333.66-58.6
	C1108.95,21.58,1220.06,40.96,1325.9,79.24z M659.21,80.92l17.8,49.14c-101.53,37.28-194.68,91.9-275.88,160.28l-33.65-40.02
	c74.62-62.93,157.76-113.96,248.06-152.16C630,92.04,644.56,86.31,659.21,80.92z M305.79,307.01
	c19.34-19.34,39.38-37.79,60.07-55.31l33.64,40.01c-80.75,68.32-149.58,150.28-202.96,242.32l-45.3-26.16
	C193.56,434.78,245.27,367.52,305.79,307.01z M96.94,616.77c15.61-36.92,33.38-72.63,53.22-107.06l45.3,26.16
	c-52.68,91.31-90.16,192.5-109,300.1l-51.52-9.09C47.53,754.88,68.23,684.63,96.94,616.77z M34.58,828.98l51.51,9.08
	c-8.87,51.35-13.5,104.14-13.5,158.02c0,54.72,4.78,108.33,13.92,160.44L35,1165.65c-9.74-55.58-14.64-112.19-14.64-169.56
	C20.36,939.57,25.13,883.77,34.58,828.98z M35.38,1167.76l51.5-9.12c19.03,107.03,56.53,207.68,109.08,298.53l-45.32,26.12
	c-20.03-34.68-37.96-70.66-53.69-107.87C68.56,1308.32,48,1238.9,35.38,1167.76z M305.79,1685.18
	c-60.29-60.29-111.85-127.28-154.09-200.05l45.33-26.12c53.75,92.44,123.1,174.69,204.46,243.14l-33.66,40.01
	C346.44,1724.13,325.75,1705.14,305.79,1685.18z M994.87,1970.61c-131.56,0-259.19-25.76-379.32-76.58
	c-89.51-37.86-171.97-88.33-246.08-150.51l33.66-40.01c160.15,134.11,366.52,214.86,591.75,214.86c0.48,0,0.96-0.01,1.44-0.01
	l0.11,52.23C995.91,1970.6,995.39,1970.61,994.87,1970.61z M998.45,1918.35c107.64-0.41,210.93-19.26,306.88-53.55l17.8,49.14
	c-103.87,37.21-212.78,56.24-324.57,56.65L998.45,1918.35z M1325.14,1913.22l-17.8-49.14c102.77-37,197.07-91.74,279.22-160.51
	l33.65,40.02c-74.09,62.15-156.53,112.6-246.01,150.45C1357.98,1900.89,1341.62,1907.28,1325.14,1913.22z M1683.96,1685.18
	c-19.98,19.98-40.7,38.99-62.11,57.03l-33.65-40.02c81.59-68.63,151.12-151.15,204.94-243.91l45.3,26.16
	C1796.13,1557.48,1744.45,1624.69,1683.96,1685.18z M1839.52,1482.6l-45.3-26.16c52.36-90.72,89.72-191.17,108.69-297.99l51.51,9.08
	c-12.62,71.22-33.2,140.72-61.6,207.88C1877.18,1412.38,1859.39,1448.13,1839.52,1482.6z M1954.79,1165.43l-51.52-9.09
	c9.12-52.05,13.89-105.59,13.89-160.26c0-54.29-4.71-107.47-13.7-159.18l51.51-9.12c9.59,55.17,14.43,111.37,14.43,168.31
	C1969.39,1053.39,1964.5,1109.93,1954.79,1165.43z M1954.6,825.68l-51.51,9.12c-18.77-106.42-55.78-206.54-107.69-297.02
	l45.33-26.12c19.39,33.83,36.77,68.89,52.09,105.11C1921.36,684.25,1941.99,754.1,1954.6,825.68z"/>
			<path d="M339.16,1021.36c-1.27,3.55-3.04,14.2-3.04,14.2s-2.54-10.65-3.55-14.2l-29.67-95.61h-22.07l-29.42,95.61l-3.8,14.2
	c-0.25-1.02-2.03-10.91-3.04-14.2l-30.69-95.61h-30.69l52.25,152.17H258l30.18-91.05c1.01-3.04,3.04-11.41,3.3-11.67
	c0,0.76,1.77,8.37,3.04,11.67l30.18,91.05h22.57l51.99-152.17h-29.67L339.16,1021.36z"/>
			<polygon points="1663.8,1074.03 1762.96,1074.03 1762.96,1049.93 1694.74,1049.93 1694.74,1005.8 1750.28,1005.8 1750.28,981.71 
	1694.74,981.71 1694.74,945.95 1762.96,945.95 1762.96,921.86 1663.8,921.86 "/>
			<path d="M1000.01,1702.03c-20.8-5.07-26.12-11.92-26.12-20.8c0-10.91,8.88-17.5,23.84-17.5c13.44,0,28.66,6.09,39.31,13.19
	l6.59-21.56c-10.14-7.86-26.88-14.96-45.65-14.96c-32.72,0-52.5,18.01-52.5,42.86c0,21.81,12.68,38.8,44.64,46.67
	c24.85,6.09,31.96,12.68,31.96,25.36c0,13.44-11.67,21.05-26.88,21.05c-20.8,0-36.78-10.91-46.92-20.54l-7.1,24.35
	c10.4,9.13,28.66,19.02,54.02,19.02c28.66,0,55.54-14.2,55.54-46.92C1050.73,1725.87,1035.51,1710.91,1000.01,1702.03z"/>
			<polygon points="1033.72,310.62 954.08,210.19 932.53,210.19 932.53,362.36 960.17,362.36 960.17,261.93 1039.81,362.36 
	1061.37,362.36 1061.37,210.19 1033.72,210.19 "/>
        </Fragment>
    )
}
