import React, { Fragment } from 'react';

export const HomeIconSVGPath = () => {
    return (
        <Fragment>
            <path d="M82.49,36.81L55.93,9.96c-3.27-3.31-8.65-3.36-11.98-0.11l-27.6,26.91h0c-1.63,1.59-2.57,3.81-2.57,6.09v42.71
	c0,4.69,3.81,8.5,8.5,8.5h16.58h21h16.58c4.69,0,8.5-3.81,8.5-8.5V42.79C84.94,40.53,84.07,38.41,82.49,36.81z M43.86,89.06v-25
	c0-0.75,0.61-1.36,1.36-1.36h8.05c0.88,0,1.59,0.71,1.59,1.59v24.77H43.86z M79.94,85.56c0,1.93-1.57,3.5-3.5,3.5H59.86V64.29
	c0-3.63-2.96-6.59-6.59-6.59h-8.05c-3.51,0-6.36,2.85-6.36,6.36v25H22.29c-1.93,0-3.5-1.57-3.5-3.5V42.85
	c0-0.94,0.39-1.85,1.06-2.51l27.6-26.91c1.37-1.34,3.59-1.32,4.94,0.04l26.55,26.85c0.65,0.66,1.01,1.53,1.01,2.46V85.56z"/>
        </Fragment>
    )
}
