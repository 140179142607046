import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from "framer-motion";



const StyledPath = styled(motion.path)`

transition-timing-function: cubic-bezier(0.07, 2.0, 1.0, 0.1);
stroke: white;
stroke-width: 4px;
`

export const Wave = (props) => {
    
    const d = [
         "m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333",
        "m17.66594,123.66569c14.55571,-0.33318 23.77811,-44.33285 38.66715,-44.66603c16.88881,0.22222 28.11099,43.77758 43.66647,43.66647c15.55548,-0.11111 28.44433,-44.33314 43.66648,-43.99981c15.44438,-0.22222 31.22208,46.22203 46.33313,46.33314c11.77772,-0.22223 31.55542,-45.11091 45.99979,-45.66647c12.99995,0.33334 29.66653,45.66646 45.33314,45.9998",
        "m19.3326,113.66573c14.55571,-0.33318 22.44478,20.33354 37.33382,20.00036c16.88881,0.22222 27.44432,-20.55547 42.99981,-20.66658c15.55548,-0.11111 27.44433,19.66658 42.66648,19.99991c15.44438,-0.22222 31.88874,-19.11102 46.99979,-18.99991c11.77772,-0.22223 29.88876,19.22214 44.33313,18.66658c12.99995,0.33334 31.66653,-18.66659 47.33313,-18.33325",
        "m17.66594,123.66569c14.55571,-0.33318 23.77811,-44.33285 38.66715,-44.66603c16.88881,0.22222 28.11099,43.77758 43.66647,43.66647c15.55548,-0.11111 28.44433,-44.33314 43.66648,-43.99981c15.44438,-0.22222 31.22208,46.22203 46.33313,46.33314c11.77772,-0.22223 31.55542,-45.11091 45.99979,-45.66647c12.99995,0.33334 29.66653,45.66646 45.33314,45.9998",
        "m19.3326,113.66573c14.55571,-0.33318 22.44478,20.33354 37.33382,20.00036c16.88881,0.22222 27.44432,-20.55547 42.99981,-20.66658c15.55548,-0.11111 27.44433,19.66658 42.66648,19.99991c15.44438,-0.22222 31.88874,-19.11102 46.99979,-18.99991c11.77772,-0.22223 29.88876,19.22214 44.33313,18.66658c12.99995,0.33334 31.66653,-18.66659 47.33313,-18.33325",
        "m17.66594,123.66569c14.55571,-0.33318 23.77811,-44.33285 38.66715,-44.66603c16.88881,0.22222 28.11099,43.77758 43.66647,43.66647c15.55548,-0.11111 28.44433,-44.33314 43.66648,-43.99981c15.44438,-0.22222 31.22208,46.22203 46.33313,46.33314c11.77772,-0.22223 31.55542,-45.11091 45.99979,-45.66647c12.99995,0.33334 29.66653,45.66646 45.33314,45.9998",
        "m19.3326,113.66573c14.55571,-0.33318 22.44478,20.33354 37.33382,20.00036c16.88881,0.22222 27.44432,-20.55547 42.99981,-20.66658c15.55548,-0.11111 27.44433,19.66658 42.66648,19.99991c15.44438,-0.22222 31.88874,-19.11102 46.99979,-18.99991c11.77772,-0.22223 29.88876,19.22214 44.33313,18.66658c12.99995,0.33334 31.66653,-18.66659 47.33313,-18.33325",
        "m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"
    ];

    return <AnimatePresence>
        <svg x="0px" y="0px" width="350" height="25" fill="none" viewBox="0 0 350 50" >

            {!props.isOpen && (<StyledPath
                style={{
                    strokeWidth: 5,
                    strokeLinecap: "round",
                    fill: "transparent",
                }}
                initial={{ d: d }}
                animate={{ d: d }}
                exit={{ d: d }}
                d="m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"
                transition={{
                    ease: [.57, .21, .69, 1.25],
                    duration: 3,
                    times: [0, 0.32, 0.48, 0.64, .8, 1],
                    delay: .3
                }}>

            </StyledPath>)}
            
            {props.isOpen && (<StyledPath
                style={{

                    strokeWidth: 5,
                    strokeLinecap: "round",
                    fill: "transparent",
                }}
                
                d="m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"

                >

            </StyledPath>)}

        </svg>
    </AnimatePresence>
    
}

export const WaveLink = (props) => {

    const e = [
        "m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333",
        "m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333",
        "m17.66594,123.66569c14.55571,-0.33318 23.77811,-44.33285 38.66715,-44.66603c16.88881,0.22222 28.11099,43.77758 43.66647,43.66647c15.55548,-0.11111 28.44433,-44.33314 43.66648,-43.99981c15.44438,-0.22222 31.22208,46.22203 46.33313,46.33314c11.77772,-0.22223 31.55542,-45.11091 45.99979,-45.66647c12.99995,0.33334 29.66653,45.66646 45.33314,45.9998",
        "m19.3326,113.66573c14.55571,-0.33318 22.44478,20.33354 37.33382,20.00036c16.88881,0.22222 27.44432,-20.55547 42.99981,-20.66658c15.55548,-0.11111 27.44433,19.66658 42.66648,19.99991c15.44438,-0.22222 31.88874,-19.11102 46.99979,-18.99991c11.77772,-0.22223 29.88876,19.22214 44.33313,18.66658c12.99995,0.33334 31.66653,-18.66659 47.33313,-18.33325",
        "m17.66594,123.66569c14.55571,-0.33318 23.77811,-44.33285 38.66715,-44.66603c16.88881,0.22222 28.11099,43.77758 43.66647,43.66647c15.55548,-0.11111 28.44433,-44.33314 43.66648,-43.99981c15.44438,-0.22222 31.22208,46.22203 46.33313,46.33314c11.77772,-0.22223 31.55542,-45.11091 45.99979,-45.66647c12.99995,0.33334 29.66653,45.66646 45.33314,45.9998",
        "m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"
    ];

    return <AnimatePresence>
        <svg x="0px" y="0px" width="350" height="25" fill="none" viewBox="0 0 350 50" >

            {props.isHovered && (<StyledPath
                style={{
                    strokeWidth: 5,
                    strokeLinecap: "round",
                    fill: "transparent",
                }}
                initial={{ d: e }}
                animate={{ d: e }}
                exit={{ d: e }}
                d="m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"
                transition={{
                    ease: [.57, .21, .69, 1.25],
                    duration: 1.5,
                    times: [0, 0.12, 0.38, 0.64, .8, 1],
                    delay: 0
                }}>

            </StyledPath>)}
            {!props.isHovered && (<StyledPath
                style={{
                    strokeWidth: 5,
                    strokeLinecap: "round",
                    fill: "transparent",
                }}

                d="m19.3326,113.66573c14.55571,-0.33318 23.77811,0.33362 38.66715,0.00044c16.88881,0.22222 26.11099,0.1111 41.66648,-0.00001c15.55548,-0.11111 27.44433,0 42.66648,0.33333c15.44438,-0.22222 31.88874,-0.1111 46.99979,0.00001c11.77772,-0.22223 29.88876,-0.11111 44.66647,0c12.99995,0 30.99986,0 46.66646,0.33333"

            >

            </StyledPath>)}
            </svg>            
    </AnimatePresence>

}

