import React, { Fragment } from 'react';

export const WindIconSVGPath = () => {
    return (
        <Fragment>
            <path d="M72.78,13.04c-11.44,0-20.05,9.62-20.17,21.24c-0.03,3.11,0,4.35,0,5.35c-0.02,1.69,1.5,3.24,3.19,3.24
	c1.68,0,3.21-1.55,3.19-3.24c0-1.13-0.03-2.24,0-5.28c0.09-8.64,5.83-14.92,13.8-14.92h1.06c7.97,0,14.86,6.51,14.86,14.89
	c0,8.38-6.89,14.89-14.86,14.89H13.33c-1.68-0.02-3.23,1.5-3.23,3.19c0,1.69,1.55,3.21,3.23,3.19h60.52
	c11.44,0,21.23-9.38,21.23-21.27s-9.79-21.27-21.23-21.27L72.78,13.04z M29.25,23.67c-6.23,0-11.68,5.12-11.68,11.7
	c-0.02,1.69,1.5,3.24,3.19,3.24c1.68,0,3.21-1.55,3.19-3.24c0-2.96,2.64-5.32,5.31-5.32h1.06c2.66,0,5.31,2.36,5.31,5.32
	c0,3.06-2.41,5.32-5.08,5.32H6.96c-1.68-0.02-3.23,1.5-3.23,3.19c0,1.69,1.55,3.21,3.23,3.19h23.59c6.23,0,11.45-5.22,11.45-11.7
	c0-6.58-5.45-11.7-11.68-11.7H29.25z M6.63,57.71c-1.67,0.09-3.11,1.69-3.02,3.36c0.09,1.67,1.68,3.11,3.35,3.02h75.38
	c4.76,0,8.49,3.74,8.49,8.51c0,4.77-3.73,8.51-8.49,8.51h-1.06c-4.76,0-8.55-3.75-8.49-8.48c0.01-1.07,0-2.11,0-3.22
	c0.02-1.69-1.5-3.24-3.19-3.24s-3.21,1.55-3.19,3.24c0,1.16,0.01,2.18,0,3.16c-0.09,8.24,6.68,14.92,14.86,14.92h1.06
	c8.18,0,14.86-6.7,14.86-14.89c0-8.19-6.68-14.89-14.86-14.89L6.63,57.71L6.63,57.71z M19.37,66.22c-1.67,0.09-3.11,1.69-3.02,3.36
	s1.68,3.11,3.35,3.02h18.05c1.68,0.02,3.23-1.5,3.23-3.19c0-1.69-1.55-3.21-3.23-3.19H19.37L19.37,66.22z"/>
        </Fragment>
    )
}
