import React, {Fragment} from 'react';

export const WaterTempSVGPath = () => {
    return (
        <Fragment>
            <path
                d="M49.51,84.69c3.46,0.01,6.79-1.35,9.26-3.78c2.47-2.43,3.88-5.73,3.93-9.2c0.05-3.46-1.27-6.81-3.67-9.31V12.57
	c0-3.4-1.82-6.55-4.76-8.25c-2.95-1.7-6.58-1.7-9.53,0c-2.95,1.7-4.76,4.85-4.76,8.25V62.4c-2.4,2.5-3.72,5.84-3.67,9.31
	c0.05,3.46,1.46,6.77,3.93,9.2C42.72,83.34,46.05,84.69,49.51,84.69L49.51,84.69z M49.51,8.21c2.41,0,4.36,1.95,4.36,4.37v52.71
	h1.02l-0.12,0.15h0c1.66,1.44,2.66,3.5,2.77,5.7c0.1,2.2-0.7,4.34-2.22,5.94c-1.52,1.59-3.62,2.49-5.83,2.49
	c-2.2,0-4.31-0.9-5.83-2.49c-1.52-1.59-2.32-3.74-2.22-5.94c0.1-2.2,1.1-4.26,2.77-5.7h0.9V12.57c-0.01-1.17,0.45-2.29,1.28-3.11
	c0.82-0.83,1.95-1.29,3.11-1.28L49.51,8.21z"/>
            <path
                d="M78.63,88.9c-2.02,0.06-4.01-0.43-5.77-1.41c-5.49-2.77-11.97-2.77-17.46,0c-3.61,1.87-7.91,1.87-11.52,0
	c-5.49-2.77-11.97-2.77-17.46,0c-3.61,1.87-7.91,1.87-11.52,0c-2.69-1.43-5.69-2.14-8.73-2.09v6.86c2.01-0.05,3.99,0.43,5.75,1.41
	c5.49,2.77,11.97,2.77,17.46,0c3.61-1.87,7.91-1.87,11.52,0c5.49,2.77,11.97,2.77,17.46,0c3.61-1.87,7.91-1.87,11.52,0
	c5.5,2.77,11.99,2.77,17.48,0c1.76-0.98,3.76-1.46,5.77-1.41v-6.86c-3.04-0.06-6.04,0.66-8.73,2.09
	C82.64,88.48,80.65,88.96,78.63,88.9L78.63,88.9z"/>
            <path
                d="M85.11,68.03c-3.84,1.27-7.99,1.27-11.83,0c-2.57-0.9-5.28-1.33-8-1.29v6.91c2.01-0.05,4.02,0.27,5.92,0.95
	c5.2,1.7,10.8,1.7,16,0c1.9-0.68,3.92-1,5.94-0.95v-6.91C90.4,66.69,87.68,67.13,85.11,68.03L85.11,68.03z"/>
            <path
                d="M19.66,75.81c2.65,0.05,5.28-0.37,7.78-1.24c1.83-0.66,3.76-0.97,5.7-0.92v-6.86
	c-2.65-0.05-5.28,0.37-7.78,1.24c-3.7,1.22-7.7,1.22-11.4,0c-2.5-0.87-5.13-1.29-7.78-1.24v6.86c1.94-0.05,3.87,0.27,5.7,0.92
	C14.38,75.44,17.02,75.86,19.66,75.81z"/>
        </Fragment>
    )
}