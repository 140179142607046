import React, { Fragment } from 'react';

export const TideSVGPath = () => {
    return (
        <Fragment>
			<path d="M76.6,64.83c-0.31-0.23-0.74-0.35-1.18-0.35h-6L63.94,24.5c-0.09-0.62-0.81-1.09-1.67-1.09h-3.52v-7.1
	c-0.02-0.42-0.33-0.8-0.83-1.01v-0.65c0-2.16-1.59-4.15-4.17-5.23c-2.58-1.08-5.75-1.08-8.33,0c-2.58,1.08-4.17,3.07-4.17,5.23v0.65
	c-0.5,0.21-0.82,0.59-0.83,1.01v7.1h-3.58c-0.86,0-1.58,0.47-1.67,1.09h0l-5.48,39.98h-6c-0.53,0-0.98,0.19-1.28,0.46v20.33h0.51
	c0.68,0.03,1.35,0.12,2.01,0.25V70.44c0-1.74,1.41-3.15,3.15-3.15h42.43c1.74,0,3.15,1.41,3.15,3.15v15.06c0.01,0,0.01,0,0.02-0.01
	c0.81-0.15,1.65-0.21,2.48-0.21h0.48V64.87C76.62,64.86,76.62,64.84,76.6,64.83z M47.09,11.51c1.55-0.65,3.45-0.65,5,0
	c1.55,0.65,2.5,1.84,2.5,3.14h-10C44.59,13.35,45.54,12.15,47.09,11.51z M42.3,64.48h-9.2l5.3-38.65h5.28L42.3,64.48z M45.64,64.48
	l1.38-38.65h5.13l1.38,38.65H45.64z M55.42,23.41H43.75l0-5.89h11.67L55.42,23.41z M56.87,64.48l-1.38-38.65h5.28l5.3,38.65H56.87z"
			/>
			<path d="M96.16,83.81c-2.36-0.05-4.67,0.79-6.57,2.4c-1.46,1.21-3.21,1.87-5.01,1.87c-1.8,0-3.55-0.65-5.01-1.87
	c-1.92-1.56-4.22-2.4-6.57-2.4c-2.35,0-4.64,0.84-6.57,2.4c-1.46,1.21-3.21,1.87-5,1.87c-1.8,0-3.55-0.65-5-1.87
	c-1.92-1.56-4.22-2.4-6.57-2.4c-2.35,0-4.64,0.84-6.57,2.4c-1.46,1.21-3.21,1.87-5,1.87c-1.8,0-3.55-0.65-5-1.87
	c-1.92-1.56-4.21-2.4-6.56-2.4c-2.35,0-4.64,0.84-6.56,2.4c-1.46,1.21-3.21,1.87-5,1.87c-1.8,0-3.55-0.65-5.01-1.87
	c-1.9-1.61-4.21-2.45-6.57-2.4c-0.8,0-1.45,0.75-1.45,1.67c0,0.92,0.65,1.67,1.45,1.67c1.8-0.05,3.57,0.61,5,1.87
	c1.9,1.61,4.21,2.45,6.57,2.4c2.35,0.05,4.66-0.79,6.55-2.4c1.46-1.21,3.21-1.87,5-1.87c1.8,0,3.55,0.65,5,1.87
	c1.92,1.56,4.22,2.4,6.57,2.4c2.35,0,4.64-0.84,6.57-2.4c1.46-1.21,3.21-1.87,5-1.87c1.8,0,3.55,0.65,5,1.87
	c1.92,1.56,4.22,2.4,6.57,2.4c2.35,0,4.64-0.84,6.57-2.4c1.46-1.21,3.21-1.87,5-1.87c1.8,0,3.55,0.65,5,1.87
	c1.92,1.56,4.22,2.4,6.57,2.4c2.35,0,4.65-0.84,6.57-2.4c1.44-1.25,3.2-1.91,5-1.87c0.38,0,0.75-0.18,1.02-0.49
	c0.27-0.31,0.42-0.74,0.42-1.18v0c0-0.44-0.15-0.87-0.42-1.18C96.91,83.99,96.54,83.81,96.16,83.81z"/>
        </Fragment>
    )
}
