import React, {Fragment} from 'react';

export const CloseSpotsSVGPath = () => {
    return (
        <Fragment>
			<g>
				<path d="M50.09,16.03c-8.45,0-15.32,6.87-15.32,15.32c0,5.83,8.46,19.04,15.32,28.2c6.86-9.16,15.32-22.38,15.32-28.2
		C65.41,22.9,58.54,16.03,50.09,16.03z M50.09,37.16c-3.37,0-6.11-2.74-6.11-6.11c0-3.37,2.74-6.11,6.11-6.11
		c3.37,0,6.11,2.74,6.11,6.11C56.2,34.42,53.46,37.16,50.09,37.16z"/>
				<path d="M89.71,67.71c-1.69,0-3.05,1.37-3.05,3.05v9.16h-33.6v-9.16c0-1.69-1.37-3.05-3.05-3.05c-1.69,0-3.05,1.37-3.05,3.05v9.16
		h-33.6v-9.16c0-1.69-1.37-3.05-3.05-3.05s-3.05,1.37-3.05,3.05v12.22c0,1.69,1.37,3.05,3.05,3.05h79.41c1.69,0,3.05-1.37,3.05-3.05
		V70.76C92.76,69.07,91.39,67.71,89.71,67.71z"/>
			</g>
        </Fragment>
    )
}
