// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraSmall": "458px",
	"small": "576px",
	"medium": "768px",
	"large": "996px",
	"extraLarge": "1200px",
	"largeDesktop": "1400px",
	"smallNum": "576",
	"mediumNum": "768",
	"largeNum": "996",
	"extraLargeNum": "1200",
	"largeDesktopNum": "1400"
};
export default ___CSS_LOADER_EXPORT___;
