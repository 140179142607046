import React, { Fragment } from 'react';

export const UvIconSVGPath = () => {
    return (
        <Fragment>
			<path d="M49.95,76.96c14.74,0,26.74-12,26.74-26.74s-12-26.74-26.74-26.74s-26.74,12-26.74,26.74S35.21,76.96,49.95,76.96z
	 M49.95,29.78c11.27,0,20.45,9.17,20.45,20.45s-9.17,20.45-20.45,20.45S29.51,61.5,29.51,50.23S38.68,29.78,49.95,29.78z"/>
			<path d="M49.95,84.49c-1.74,0-3.15,1.41-3.15,3.15v7.07c0,1.74,1.41,3.15,3.15,3.15c1.74,0,3.15-1.41,3.15-3.15v-7.07
	C53.1,85.9,51.69,84.49,49.95,84.49z"/>
			<path d="M90.06,69.75l-6.12-3.54c-0.49-0.28-1.04-0.42-1.57-0.42c-1.08,0-2.14,0.57-2.72,1.57c-0.86,1.5-0.35,3.42,1.15,4.3
	l6.12,3.54c1.5,0.86,3.42,0.36,4.3-1.15C92.07,72.53,91.56,70.61,90.06,69.75L90.06,69.75z"/>
			<path d="M88.48,24.85c-0.54,0-1.07,0.13-1.57,0.42l-6.12,3.54c-1.51,0.86-2.02,2.79-1.15,4.3c0.87,1.51,2.8,2.02,4.3,1.15l6.12-3.54
	c1.51-0.86,2.02-2.79,1.15-4.3C90.62,25.4,89.56,24.85,88.48,24.85L88.48,24.85z"/>
			<path d="M49.95,2.6c-1.74,0-3.15,1.41-3.15,3.15v7.07c0,1.74,1.41,3.15,3.15,3.15c1.74,0,3.15-1.41,3.15-3.15V5.75
	C53.1,4,51.69,2.6,49.95,2.6z"/>
			<path d="M9.85,30.71l6.12,3.54c1.5,0.87,3.42,0.35,4.3-1.15c0.86-1.5,0.35-3.42-1.15-4.3L13,25.26c-0.49-0.28-1.04-0.42-1.57-0.42
	c-1.08,0-2.14,0.57-2.72,1.57C7.84,27.92,8.35,29.84,9.85,30.71L9.85,30.71z"/>
			<path d="M13.01,75.19l6.12-3.54c1.51-0.86,2.02-2.79,1.15-4.3c-0.58-1.01-1.64-1.57-2.72-1.57c-0.54,0-1.07,0.13-1.57,0.42
	l-6.12,3.54c-1.51,0.86-2.02,2.79-1.15,4.3C9.58,75.55,11.51,76.06,13.01,75.19L13.01,75.19z"/>
        </Fragment>
    )
}
