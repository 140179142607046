import React, {Fragment} from 'react';

export const MultiSpotsSVGPath = () => {
    return (
        <Fragment>
			<g>
				<g>
					<path d="M42.83,9.72c0.56,0,1.02,0.46,1.02,1.02v32.33c0,0.56-0.46,1.02-1.02,1.02H10.49c-0.56,0-1.02-0.46-1.02-1.02V10.73
			c0-0.56,0.46-1.02,1.02-1.02H42.83 M42.83,6.26H10.49c-2.47,0-4.48,2-4.48,4.48v32.33c0,2.47,2,4.48,4.48,4.48h32.33
			c2.47,0,4.48-2,4.48-4.48V10.73C47.3,8.26,45.3,6.26,42.83,6.26L42.83,6.26z"/>
					<path d="M26.66,16.64c-3.64,0-6.58,2.94-6.58,6.61c0,0.95,0.21,1.83,0.54,2.66l5.68,11.02c0.15,0.31,0.57,0.31,0.72,0l5.68-11.02
			c0.36-0.8,0.54-1.7,0.54-2.66C33.24,19.59,30.3,16.64,26.66,16.64L26.66,16.64z M26.66,25.55c-1.29,0-2.35-1.06-2.35-2.35
			c0-1.29,1.06-2.35,2.35-2.35c1.29,0,2.35,1.06,2.35,2.35C29.01,24.49,27.95,25.55,26.66,25.55z"/>
				</g>
				<g>
					<path d="M89.51,9.72c0.56,0,1.02,0.46,1.02,1.02v32.33c0,0.56-0.46,1.02-1.02,1.02H57.17c-0.56,0-1.02-0.46-1.02-1.02V10.73
			c0-0.56,0.46-1.02,1.02-1.02H89.51 M89.51,6.26H57.17c-2.47,0-4.48,2-4.48,4.48v32.33c0,2.47,2,4.48,4.48,4.48h32.33
			c2.47,0,4.48-2,4.48-4.48V10.73C93.98,8.26,91.98,6.26,89.51,6.26L89.51,6.26z"/>
					<path d="M73.34,16.64c-3.64,0-6.58,2.94-6.58,6.61c0,0.95,0.21,1.83,0.54,2.66l5.68,11.02c0.15,0.31,0.57,0.31,0.72,0l5.68-11.02
			c0.36-0.8,0.54-1.7,0.54-2.66C79.92,19.58,76.98,16.64,73.34,16.64L73.34,16.64z M73.34,25.54c-1.29,0-2.35-1.06-2.35-2.35
			c0-1.29,1.06-2.35,2.35-2.35c1.29,0,2.35,1.06,2.35,2.35C75.69,24.49,74.63,25.54,73.34,25.54z"/>
				</g>
				<g>
					<path d="M42.83,55.69c0.56,0,1.02,0.46,1.02,1.02v32.33c0,0.56-0.46,1.02-1.02,1.02H10.49c-0.56,0-1.02-0.46-1.02-1.02V56.71
			c0-0.56,0.46-1.02,1.02-1.02H42.83 M42.83,52.23H10.49c-2.47,0-4.48,2-4.48,4.48v32.33c0,2.47,2,4.48,4.48,4.48h32.33
			c2.47,0,4.48-2,4.48-4.48V56.71C47.3,54.24,45.3,52.23,42.83,52.23L42.83,52.23z"/>
					<path d="M26.66,62.62c-3.64,0-6.58,2.94-6.58,6.61c0,0.95,0.21,1.83,0.54,2.66L26.3,82.9c0.15,0.31,0.57,0.31,0.72,0l5.68-11.02
			c0.36-0.8,0.54-1.7,0.54-2.66C33.24,65.56,30.3,62.62,26.66,62.62L26.66,62.62z M26.66,71.52c-1.29,0-2.35-1.06-2.35-2.35
			c0-1.29,1.06-2.35,2.35-2.35c1.29,0,2.35,1.06,2.35,2.35C29.01,70.46,27.95,71.52,26.66,71.52z"/>
				</g>
				<g>
					<path d="M89.51,55.92c0.56,0,1.02,0.46,1.02,1.02v32.33c0,0.56-0.46,1.02-1.02,1.02H57.17c-0.56,0-1.02-0.46-1.02-1.02V56.94
			c0-0.56,0.46-1.02,1.02-1.02H89.51 M89.51,52.46H57.17c-2.47,0-4.48,2-4.48,4.48v32.33c0,2.47,2,4.48,4.48,4.48h32.33
			c2.47,0,4.48-2,4.48-4.48V56.94C93.98,54.46,91.98,52.46,89.51,52.46L89.51,52.46z"/>
					<path d="M73.34,62.85c-3.64,0-6.58,2.94-6.58,6.61c0,0.95,0.21,1.83,0.54,2.66l5.68,11.02c0.15,0.31,0.57,0.31,0.72,0l5.68-11.02
			c0.36-0.8,0.54-1.7,0.54-2.66C79.92,65.79,76.98,62.85,73.34,62.85L73.34,62.85z M73.34,71.75c-1.29,0-2.35-1.06-2.35-2.35
			c0-1.29,1.06-2.35,2.35-2.35c1.29,0,2.35,1.06,2.35,2.35C75.69,70.69,74.63,71.75,73.34,71.75z"/>
				</g>
			</g>
        </Fragment>
    )
}
